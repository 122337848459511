import {
	HttpContextToken,
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import { from, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { AuthService, RudderStack } from 'src/app/services';

export const BYPASS_LOG = new HttpContextToken(() => false);

@Injectable()
export class InterceptorService implements HttpInterceptor {
	constructor(
		private router: Router,
		private authService: AuthService,
		private rudderStack: RudderStack,
	) {}

	intercept(
		httpRequest: HttpRequest<unknown>,
		next: HttpHandler,
	): Observable<HttpEvent<any>> {
		if (this.router.url.search('/app') === 0) {
			const fb: any = firebase;

			this.rudderStack.log({
				type: 'admin-tool',
				event: 200,
				URL: httpRequest.url,
				body: httpRequest.body,
				method: httpRequest.method,
			});

			return from(fb.auth()?.currentUser?.getIdToken()).pipe(
				switchMap(token => {
					const modifiedReq = httpRequest.clone({
						setHeaders: {
							// eslint-disable-next-line @typescript-eslint/naming-convention
							Authorization: 'Bearer ' + token,
						},
					});
					return next.handle(modifiedReq).pipe(
						filter((event: HttpEvent<any>) => event.type !== 0),
						tap((event: any) => {
							if (modifiedReq.method !== 'GET') {
								if (event.body.message) {
									if (httpRequest.context.get(BYPASS_LOG) === true) {
									} else {
										this.authService.addToastMsg(event.body.message);
									}
								}
							}
						}),
						catchError((errors: HttpErrorResponse) => {
							const { status, url, message } = errors;
							let errorMsg = '';

							switch (status) {
								case 401:
									this.authService.signOut();
									break;
								case 404:
								case 400:
									if(url && url.indexOf('/set/priority')){
										errorMsg = errors.error.error || errors.error || message;
									}
									else if (url && url.indexOf('https://hubble') === 0) {
										errorMsg = errors.error.error || errors.error || message;
									} else {
										errorMsg = `Error Code: ${status},  Message: ${message}`;
									}
									break;
								case 405:
									errorMsg = `${url}, Error Code: ${status},  Message: ${message}`;
									break;
								default:
									break;
							}
							if (errors.statusText === 'Unknown Error') {
								errorMsg = `${url}, Unknown Error`;
							}
							if (errorMsg) {
								this.authService.addToastMsg(errorMsg);
							}
							return throwError(errorMsg);
						}),
					);
				}),
			);
		} else {
			return next.handle(httpRequest);
		}
	}
}
